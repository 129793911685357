import React from 'react';

import { bindings, hook } from '@vl/redata';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import _ from 'lodash';
import fbFnsClient from '@vl/mod-clients/fibGatsbyFns';
import usePromiseSource from '@vl/hooks/usePromiseSource';
import useObservableSource from '@vl/hooks/useObservableSource';
import BankModel from '@uz/unitz-models/BankModel';
import CardWalletModel from '@uz/unitz-models/CardWalletModel';
import UserWalletModelFormatter from '@uz/unitz-models/UserWalletModel/formatter';
import UserWalletModel from '@uz/unitz-models/UserWalletModel';
import useRoute from '@vl/hooks/useGbRoute';
import PurchaseModelFormatter from '@uz/unitz-models/PurchaseModel/formatter';

const isPaymentIntentSecret = (val) => {
  return _.startsWith(val, 'pi_') && !!`${val}`.indexOf('_secret_');
};

const formRef = {
  prevValues: {},
  currValues: {},
  addValues: (val) => {
    const prevValues = _.cloneDeep(val);
    formRef.currValues = _.cloneDeep(val);
    setTimeout(() => {
      formRef.prevValues = prevValues;
    });
  },
  isChanged: (...args) => {
    const [dataPath, toVal] = args;
    const prevVal = _.get(formRef.prevValues, dataPath);
    const newVal = _.get(formRef.currValues, dataPath);
    const isChanged = !_.isEqual(prevVal, newVal);
    if (args.length === 2) {
      return isChanged && _.isEqual(toVal, newVal);
    }
    return isChanged;
  },
};

const paymentFormReducer = (ctx) => (formValues) => {
  formRef.addValues(formValues);

  const paymentMethodCardIndex = _.findIndex(_.get(formValues, 'payments'), ({ type }) => type === 'payment');
  const paymentMethodCard = _.get(formValues, `payments.${paymentMethodCardIndex}`);
  const paymentMethodVisaIndex = _.findIndex(_.get(formValues, 'payments'), ({ type }) => type === 'card');
  const paymentMethodVisa = _.get(formValues, `payments.${paymentMethodVisaIndex}`);
  const paymentMethodTokenIndex = _.findIndex(_.get(formValues, 'payments'), ({ type }) => type === 'token');
  const paymentMethodToken = _.get(formValues, `payments.${paymentMethodTokenIndex}`);

  const newValues = {
    ...formValues,
    payments: _.compact([paymentMethodVisa, paymentMethodCard, paymentMethodToken]),
  };

  return newValues;
};

const topupFormValues = {
  amountMin: 20000,
  amountInit: 50000,
  amountMax: 5000000,
};

const paymentFormValidation = (ctx) => () => {
  return Yup.object().shape({
    amount: Yup.number()
      .required(ctx.apply('i18n.t', 'Topup.Error.amount'))
      .max(topupFormValues.amountMax, 'max value is 5.000.000 vnd')
      .min(topupFormValues.amountMin, 'min value is 10.000 vnd'),
    payments: Yup.array().of(
      Yup.lazy((object) => {
        const type = _.get(object, 'type');
        // validate atm card payment
        if (type === 'payment' && !_.get(object, 'bank_code') && _.get(object, 'selected')) {
          return Yup.object().shape({
            bank_code: Yup.string().required(ctx.apply('i18n.t', 'Payment.Error.bank')),
          });
        }
        // validate visa card payment
        if (type === 'card' && _.get(object, 'selected')) {
          return Yup.object();
        }
        // validate token payment
        if (type === 'token' && !_.get(object, 'card_id') && _.get(object, 'selected')) {
          return Yup.object().shape({
            bank_code: Yup.string().required(ctx.apply('i18n.t', 'Payment.Error.token')),
          });
        }
        return Yup.object();
      })
    ),
  });
};

const bindData = bindings({
  topupModal: {
    rules: [
      [
        'data',
        {
          data: {
            cards: hook((ctx) => {
              const userId = ctx.apply('authModel.getUserId');
              const items = useObservableSource(() =>
                CardWalletModel.find(
                  `where: {user_id: {_eq: "${userId}"}} limit: 20`,
                  `id user_id is_default card_number bank_code card_type token
                  bank_detail {
                    name
                    logo_url
                  }
                  `
                )
              );
              return items;
            }),

            banks: hook(() => {
              const items = usePromiseSource(
                async () => {
                  try {
                    const items = await BankModel.find(
                      'where: { method: {_eq: "bankCard"}}',
                      'id name bank_code logo_url'
                    );
                    return items.toObject();
                  } catch (err) {}
                },
                [],
                []
              );
              return items;
            }),

            myWallets: hook((ctx) => {
              const userId = ctx.apply('authModel.getUserId');
              const wallet = useObservableSource(() =>
                UserWalletModel.find(
                  `where: {user_id: {_eq: "${userId}"} wallets: { wallet_type: {_eq: credit}}}`,
                  `
                    wallets {
                      id
                      name
                      wallet_type
                      balance {
                        amount
                        amount_available
                        id
                      }
                    }
                    id
                  `
                )
              );
              return wallet;
            }),

            wallet: hook((ctx) => {
              const userId = ctx.apply('authModel.getUserId');
              const walletData = usePromiseSource(
                async () => {
                  try {
                    const items = await UserWalletModel.find(
                      `where: {user_id: {_eq: "${userId}"} wallets: { wallet_type: {_eq: credit}}}`,
                      `
                    wallets {
                      id
                      name
                      wallet_type
                      balance {
                        amount
                        amount_available
                        id
                      }
                    }
                    id
                  `
                    );
                    return items.toObject();
                  } catch (err) {}
                },
                {},
                []
              );
              const wallets = _.get(walletData, '0.wallets');
              const result = {
                id: UserWalletModelFormatter.id(ctx)(wallets),
                amountAvailable: UserWalletModelFormatter.available(ctx)(wallets),
                amountAvailableValue: UserWalletModelFormatter.availableValue(ctx)(wallets),
                amount: UserWalletModelFormatter.amount(ctx)(wallets),
                currency: 'đ',
              };
              return result;
            }),

            form: hook((ctx) => {
              const route = useRoute();
              const [isModalVisible, setIsModalVisible] = React.useState(true);
              const userId = ctx.apply('authModel.getUserId');
              const topup = userId;
              const initialValues = React.useMemo(
                () =>
                  paymentFormReducer(ctx)({
                    amount: topupFormValues.amountInit,
                    currency: 'vnd',
                    payments: [
                      {
                        type: 'token',
                        amount: 0,
                        selected: false,
                        bank_code: '',
                        return_url: route.redirectUrl({ status: 'confirm', topup }),
                        cancel_url: route.redirectUrl({ status: 'cancel', topup }),
                      },
                      {
                        type: 'payment',
                        amount: 0,
                        selected: false,
                        bank_code: '',
                        return_url: route.redirectUrl({ status: 'confirm', topup }),
                        cancel_url: route.redirectUrl({ status: 'cancel', topup }),
                      },
                      {
                        type: 'card',
                        amount: 0,
                        selected: false,
                        return_url: route.redirectUrl({ status: 'confirm', topup }),
                        cancel_url: route.redirectUrl({ status: 'cancel', topup }),
                      },
                    ],
                  }),
                []
              );

              const form = useFormik({
                initialValues,
                onSubmit: async (values, actions) => {
                  try {
                    const userId = ctx.apply('authModel.getUserId');
                    const sessionInputs = PurchaseModelFormatter.normalizeTopupForm(ctx)({
                      user_id: userId,
                      ...values,
                    });
                    const data = await fbFnsClient.getClient().post('purchase-createTopup', {
                      ...sessionInputs,
                    });
                    if (_.get(data, 'code') === '00') {
                      const paymentUrl = _.get(data, 'data');
                      if (paymentUrl) {
                        // for authentication url
                        if (
                          await Yup.string()
                            .url()
                            .isValid(paymentUrl)
                        ) {
                          // redirect payment form
                          return route.navigateExternal(paymentUrl);
                        }
                        if (isPaymentIntentSecret(paymentUrl)) {
                          const result = await ctx.apply('stripe.stripe.handleCardAction', paymentUrl);
                          if (_.get(result, 'error')) {
                            // Show `result.error.message` in payment form
                            throw Error(`Payment error: ${_.get(result, 'error.message')}`);
                          } else {
                            const payment_intent_id = _.get(result, 'paymentIntent.id');
                            await fbFnsClient.getClient().post('stripe-confirmPaymentIntent', {
                              payment_intent_id,
                            });
                          }
                        }
                      }
                      // move to next step confirm topup
                      const purchase_id = _.get(data, 'id');
                      const status = 'confirm';
                      const toUrl = route.redirectUrl({ status, purchase_id, topup: userId }, { origin: false });
                      route.navigate(toUrl, { relace: true });
                      return false;
                    }
                  } catch (error) {
                    actions.setStatus({
                      error,
                    });
                  }
                },
                validationSchema: paymentFormValidation(ctx)(),
              });

              const paymentMethods = ['token', 'payment', 'card', 'momo'];

              _.assign(form, {
                isModalVisible,
                canSubmit: !_.get(form, 'isSubmitting') && _.get(form, 'isValid'),
                showModal: () => {
                  setIsModalVisible(true);
                },
                handleCancel: () => {
                  setIsModalVisible(false);
                  const toUrl = route.redirectUrl(
                    { status: undefined, purchase_id: undefined, topup: undefined },
                    { origin: false }
                  );
                  route.navigate(toUrl, { relace: true });
                },
                handleChange: async (event) => {
                  // Listen for changes in the CardElement
                  // and display any errors as the customer types their card details
                  // setDisabled(event.empty);
                  if (event.complete) {
                    form.setFieldValue('cardNumber', event.complete ? 'complete' : '');
                  }
                  form.setStatus({
                    error: event.error ? event.error.message : '',
                  });
                },
                togglePaymentMethod: (item) => {
                  const payments = [...ctx.get('form.values.payments', [])];
                  payments.map((payment) => {
                    if (payment.type === item.type) {
                      payment.selected = !payment.selected;
                    }
                  });
                  ctx.apply('form.setFieldValue', 'payments', payments);
                },
                hasPaymentMethod: (item) => {
                  const payments = ctx.get('form.values.payments', []);
                  const found = _.find(payments, (payment) => !!payment.selected && payment.type === item.type);
                  return !!found;
                },
                selectPaymentMethod: (item, attrs) => {
                  const payments = [...ctx.get('form.values.payments', [])];
                  payments.map((payment) => {
                    if (paymentMethods.includes(payment.type)) {
                      payment.selected = false;
                      if (payment.type === item.type) {
                        payment.selected = true;
                        if (attrs) {
                          _.assign(payment, attrs);
                        }
                      }
                    }
                  });
                  ctx.apply('form.setFieldValue', 'payments', payments);
                },
              });
              React.useEffect(() => {
                const formValues = paymentFormReducer(ctx)(form.values);
                form.setValues(formValues);
              }, [JSON.stringify({ values: form.values })]);
              return form;
            }),
            canSubmit: hook((ctx) => {
              return ctx.get('form.isValid') && !ctx.get('form.isSubmitting') && ctx.get('form.dirty');
            }),
          },
        },
      ],
    ],
  },
  confirmStep: {
    rules: [
      [
        'display',
        {
          display: hook(
            (ctx) => _.get(useRoute(), 'params.purchase_id') && _.get(useRoute(), 'params.status') === 'confirm'
          ),
        },
      ],
    ],
  },
  cancelStep: {
    rules: [
      [
        'display',
        {
          display: hook(
            (ctx) => _.get(useRoute(), 'params.purchase_id') && _.get(useRoute(), 'params.status') === 'cancel'
          ),
        },
      ],
    ],
  },
  processingStep: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => _.get(useRoute(), 'params.purchase_id') && !!_.get(useRoute(), 'params.topup')),
        },
      ],
    ],
  },
  topupStep: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => !_.get(useRoute(), 'params.purchase_id')),
        },
      ],
    ],
  },
});

export default bindData;

import _ from 'lodash';
import graphme from '@unitz/graphme';

import walletClient from '@vl/mod-clients/walletApp';

import GetUserWallet from 'unitz-gql/user_wallet/GetUserWallet.gql';

graphme.GqlBuilder.loadDocument({
  GetUserWallet
});

class UserWalletModel extends graphme.BaseModel {
  static DEFINITION = graphme.Definition.create({
    name: 'UserWalletModel',
    schema: {
      id: String
    },
    nodes: [],
    key: 'id',

    baseQuery: 'GetUserWallet',

    GQL_ACTIONS: _.memoize(() => {
      return {
        GET: 'user_by_pk',
        FIND: 'user'
      };
    })(),

    getClient: walletClient.getClient
  });
}

graphme.model({ UserWalletModel });

export default UserWalletModel;

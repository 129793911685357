import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

import { Modal } from 'antd';
import styled from 'styled-components';
import { Form, FormItem } from 'formik-antd';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
} from 'react-share';

import FormProvider from '@uz/unitz-components-web/FormProvider';
import InputText from '@uz/unitz-components-web/InputText';
import Button from '@uz/unitz-components-web/Button';
import gstyles from '@vl/gstyles';

const CustomInput = styled.div`
  textarea,
  input {
    border-radius: 8px;
    border-color: ${gstyles.colors.divider};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    &::placeholder {
      color: ${gstyles.colors.placeHolder};
      font-size: 16px;
    }
  }
`;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export default function ShareLinkModal() {
  return (
    <DIV className="shareLinkModal">
      <Modal
        title={ctx.get('shareLinkModel.options.title')}
        visible={ctx.get('form.isModalVisible')}
        onCancel={ctx.get('form.handleCancel')}
        okButtonProps={{
          style: { display: 'none' },
        }}
        afterClose={() => {
          ctx.apply('shareLinkModel.hide');
        }}
        footer={null}
      >
        {ctx.get('form.isModalVisible') && (
          <FormProvider form={ctx.get('form')}>
            <Form className="course-form">
              <div className="flex mb-6">
                <FacebookShareButton className="mr-2" {...ctx.get('item.facebook')}>
                  <FacebookIcon size={40} round={true} />
                </FacebookShareButton>
                <TwitterShareButton className="mr-2" {...ctx.get('item.twitter')}>
                  <TwitterIcon size={40} round={true} />
                </TwitterShareButton>
                <LinkedinShareButton className="mr-2" {...ctx.get('item.linkedin')}>
                  <LinkedinIcon size={40} round={true} />
                </LinkedinShareButton>
                <EmailShareButton className="mr-2" {...ctx.get('item.email')}>
                  <EmailIcon size={40} round={true} />
                </EmailShareButton>
              </div>
              <div className="flex">
                <FormItem className="flex-1" name="shareUrl">
                  <CustomInput>
                    <InputText
                      className="round-xl"
                      name="shareUrl"
                      size="medium"
                      disabled
                      placeholder={ctx.apply('i18n.t', 'Course.Info.name_input_placeholder')}
                    />
                  </CustomInput>
                </FormItem>
                <Button type="ink" className="ml-5 w-36" onClick={ctx.get('form.copyLink')}>
                  {ctx.apply('i18n.t', 'Course.Action.copy_url')}
                </Button>
              </div>
            </Form>
          </FormProvider>
        )}
      </Modal>
    </DIV>
  );
}

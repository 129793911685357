import { bindings, hook } from '@vl/redata';

const bindData = bindings({
  paymentMethods: {
    rules: [
      [
        'data',
        {
          data: {
            '@item': hook((ctx) => {
              const banks = (ctx.get('banks') || []).map((bank) => ({
                ...bank,
                value: bank.bank_code,
                label: bank.name,
              }));

              const cards = (ctx.get('cards', []) || []).map((item) => ({
                ...item,
                value: item.id,
                label: `${item.card_number}`,
              }));

              const lists = [
                {
                  id: 'token',
                  label: ctx.apply('i18n.t', 'Payment.Method.myCard'),
                  type: 'token',
                  banks: cards,
                },
                {
                  id: 'payment',
                  label: ctx.apply('i18n.t', 'Payment.Method.domesticDebitCard'),
                  type: 'payment',
                  banks,
                },
                {
                  id: 'card',
                  label: ctx.apply('i18n.t', 'Payment.Method.visaMasterCard'),
                  type: 'card',
                },
                {
                  id: 'momo',
                  label: 'Momo Wallet',
                  type: 'momo',
                },
              ];
              return {
                lists,
              };
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;

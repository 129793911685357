import _ from 'lodash';
import { formatVND } from '@vl/mod-utils/currencyFormatter';

export const formatter = {
  id: () => (itemData) => {
    const id = _.get(itemData, '0.id', '');
    return id;
  },
  amountBalance: () => (itemData) => {
    const amount_available = _.get(itemData, '0.balance.amount_available', 0);

    if (!amount_available) return 0;

    return `${formatVND(amount_available)}`;
  },

  amount: () => (itemData) => {
    const amount_available = _.get(itemData, '0.balance.amount', 0);

    if (!amount_available) return 0;

    return `${formatVND(amount_available)}`;
  },
  available: () => (itemData) => {
    const amount_available = _.get(itemData, '0.balance.amount_available', 0);

    if (!amount_available) return 0;
    return `${formatVND(amount_available)}`;
  },
  availableBalance: () => (itemData) => {
    const amount_available = _.get(itemData, '0.balance.amount_available', 0);

    if (!amount_available) return 0;
    return `${formatVND(amount_available)}`;
  },
  availableValue: () => (itemData) => {
    const amount_available = _.get(itemData, '0.balance.amount_available', 0);

    if (!amount_available) return 0;

    return amount_available;
  },
};

export default formatter;

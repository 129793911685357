import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import { renderPropsComposer } from '@vl/mod-utils/PropsComposer';
import { resable } from '@vl/redata/RESABLE';
import RESABLE from '@vl/redata/RESABLE.macro';

import IconStatus from '@uz/unitz-components-web/TransactionList/components';

const View8 = () => {
  return (
    <DIV className="forPurchaseView">
      <DIV className="purchaseData">
        <RESABLE>
          <DIV forceCtx>
            {ctx.debug(() => {
              const purchaseData = resable.for('purchaseData')(ctx.get('purchaseData'), []);
              ctx.set('purchaseData', _.get(purchaseData, '0'));
            })}
            <DIV className="topupConfirm">
              <div className="flex flex-col items-center p-6">
                {renderPropsComposer(
                  {
                    matcher: (props) => _.get(props, 'status') === 'completed',
                    render: (props) => IconStatus.completed({ name: _.get(props, 'name') }),
                  },
                  {
                    matcher: (props) => _.get(props, 'status') === 'pending',
                    render: (props) => IconStatus.pending({ name: _.get(props, 'name') }),
                  },
                  {
                    matcher: (props) => _.get(props, 'status') === 'reject',
                    render: (props) => IconStatus.reject({ name: _.get(props, 'name') }),
                  },
                  () =>
                    IconStatus.unknown({ name: ctx.apply('i18n.t', `Transaction.Status.${ctx.get('@items.status')}`) })
                )({
                  status: ctx.get('@items.status'),
                  name: ctx.apply('i18n.t', `Transaction.Status.${ctx.get('@items.status')}`),
                })}
              </div>
            </DIV>
          </DIV>
        </RESABLE>
      </DIV>
    </DIV>
  );
};

export default displayName(View8);

import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import useRoute from '@vl/hooks/useGbRoute';
import { useLocalStorage } from '@vl/hooks/useLocalStorageWeb';

const EffectToForceSync = () => {
  const route = useRoute();
  const [syncAuth, $syncAuth] = useLocalStorage('@NA::syncAuth', false);

  syncAuth !== false && $syncAuth(false);
  setTimeout(() => {
    try {
      const urlObj = new URL(route.location.href);
      urlObj.searchParams.delete('__sync');
      route.navigate(urlObj.toString());
    } catch (err) {
      console.log('redirect error', err);
    }
  });
  return null;
};

const View8 = ({ children }) => {
  const route = useRoute();
  if (route.params.__sync) {
    return <EffectToForceSync />;
  }

  return (
    <DIV className="component 779BD3C8-CDF6-4067-A5A5-C1B320DFF758">
      {ctx.get('authModel.initing') ? null : children}
    </DIV>
  );
};

export default displayName(View8);

const _ = require('lodash');
const modConfig = require('@vl/mod-config');
const { loadStripe } = require('@stripe/stripe-js');

const getOptions = () => {
  const options = {
    publishable_key: modConfig.get('STRIPE_PUBLISHABLE_KEY') || process.env.STRIPE_PUBLISHABLE_KEY,
    debug: modConfig.get('STRIPE_CONFIG_DEBUG') || false,
  };
  return options;
};

exports.getClient = _.memoize(() => {
  const options = getOptions();

  const PUBLISHABLE_KEY = _.get(options, 'publishable_key');
  const client = loadStripe(PUBLISHABLE_KEY);
  return client;
});

import React from 'react';
import _ from 'lodash';
import { AutoComplete as $AutoComplete, Input } from 'antd';
import { Field } from 'formik-antd';
import { formatComas, normalizeNumber, suggestPrice } from '@vl/mod-utils/currencyFormatter';

export const InputCurrency = ({ name, validate, fast, onChange, onBlur, ...restProps }) => {
  const ref = React.useRef({});
  const [options, setOptions] = React.useState([]);

  const onChangeHandle = (value, option) => {
    value = normalizeNumber(value);
    ref.current.form && ref.current.form.setFieldValue(name, value != null ? value.valueOf() : value);
    onChange && onChange(value, option);
  };
  const onBlurHandle = (value) => {
    value = normalizeNumber(value);
    ref.current.form && ref.current.form.setFieldTouched(name);
    onBlur && onBlur(value);
  };

  const onSearchHandle = (searchText) => {
    const value = normalizeNumber(searchText);
    setOptions(
      !searchText
        ? []
        : _.map(suggestPrice(value), (val) => ({
            value: formatComas(val),
            label: `${formatComas(val)} ${_.get(restProps, 'suffix', '')}`,
          }))
    );
  };

  return (
    <Field name={name} validate={validate} fast={fast}>
      {({ field: { value }, form }) => {
        ref.current.form = form;
        return (
          <$AutoComplete
            value={formatComas(value)}
            onChange={onChangeHandle}
            onBlur={onBlurHandle}
            options={options}
            onSearch={onSearchHandle}
            {...restProps}
          >
            <Input {...restProps} />
          </$AutoComplete>
        );
      }}
    </Field>
  );
};

export default InputCurrency;

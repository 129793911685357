import React from 'react';
import cx from 'classnames';
import gstyles from '@vl/gstyles';
import displayName from '@vl/redata/displayName.macro';

const View8 = (props) => {
  const prop = props || {};
  return (
    <div {...prop} className={cx('flex items-center', { 'cursor-pointer': !!prop.onClick })}>
      {gstyles.icons({
        name: 'check-circle', fill: gstyles.colors.brandb500, size: 20, className: 'mr-2'
      })}
      {prop.name}
    </div>
  );
};
export default displayName(View8);

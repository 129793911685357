import _ from 'lodash';
import graphme from '@unitz/graphme';

import walletClient from '@vl/mod-clients/walletApp';

import GetBank from 'unitz-gql/banks/GetBank.gql';

graphme.GqlBuilder.loadDocument({
  GetBank
});

class BankModel extends graphme.BaseModel {
  static DEFINITION = graphme.Definition.create({
    name: 'Transaction',
    schema: {
      id: String,
      name: String,
      logo_url: String,
      bank_code: String
    },
    nodes: [],
    key: 'id',

    baseQuery: 'GetBank',

    GQL_ACTIONS: _.memoize(() => {
      return {
        GET: 'bank_by_pk',
        FIND: 'bank',
        INSERT: 'insert_bank_one',
        INSERT_MANY: 'insert_bank',
        UPDATE: 'update_bank_by_pk',
        DELETE: 'delete_bank_by_pk',
        DELETE_MANY: 'delete_bank'
      };
    })(),

    getClient: walletClient.getClient
  });
}

graphme.model({ BankModel });

export default BankModel;

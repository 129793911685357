import React from 'react';

import { bindings, hook } from '@vl/redata';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import _ from 'lodash';
import fbFnsClient from '@vl/mod-clients/fibGatsbyFns';

const bindData = bindings({
  stripeAddCardForm: {
    rules: [
      [
        'data',
        {
          data: {
            addCardForm: hook((ctx) => {
              const stripe = useStripe();
              const elements = useElements();
              const [isModalVisible, setIsModalVisible] = React.useState(false);
              const form = useFormik({
                initialValues: {
                  cardNumber: '',
                },
                onSubmit: async (values, actions) => {
                  try {
                    const cardElement = elements.getElement(CardElement);

                    const resPaymentMethod = await stripe.createPaymentMethod({
                      type: 'card',
                      card: cardElement,
                      billing_details: {
                        name: 'cardholderName',
                      },
                    });
                    const paymentMethodId = _.get(resPaymentMethod, 'paymentMethod.id');
                    const data = await fbFnsClient.getClient().post('stripe-createSetupIntent', {
                      user_id: ctx.apply('authModel.getUserId'),
                      payment_id: paymentMethodId,
                    });
                    if (_.get(data, 'errors')) {
                      throw Error(data.message);
                    }
                    if (_.get(data, 'error')) {
                      throw Error(_.get(data, 'error.message'));
                    }
                    setIsModalVisible(false);
                  } catch (error) {
                    actions.setStatus({
                      error,
                    });
                  }
                },
                validationSchema: Yup.object().shape({
                  cardNumber: Yup.string().required(ctx.apply('i18n.t', 'Booking.PaymentEditCard.required')),
                }),
              });
              _.assign(form, {
                isModalVisible,
                canSubmit: !_.get(form, 'isSubmitting') && _.get(form, 'isValid'),
                showModal: () => {
                  setIsModalVisible(true);
                },
                handleCancel: () => {
                  setIsModalVisible(false);
                },
                handleChange: async (event) => {
                  // Listen for changes in the CardElement
                  // and display any errors as the customer types their card details
                  // setDisabled(event.empty);
                  if (event.complete) {
                    form.setFieldValue('cardNumber', event.complete ? 'complete' : '');
                  }
                  form.setStatus({
                    error: event.error ? event.error.message : '',
                  });
                },
              });
              return form;
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;

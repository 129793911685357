import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import useRoute from '@vl/hooks/useGbRoute';

import { useLocalStorage } from '@vl/hooks/useLocalStorageWeb';
import { useAsyncCallState } from '@vl/hooks/useAsyncCallState';
import { createPromise } from '@vl/mod-utils/createPromise';

import firebase from 'gatsby-plugin-firebase';
import qs from 'querystring';
import fibGatsbyFns from '@vl/mod-clients/fibGatsbyFns';
import modConfig from '@vl/mod-config/web';

const isBrowser = () => typeof window !== 'undefined';

const getFirebase = _.memoize(() => {
  return firebase;
});

const checkEmailVerification = async (user) => {
  const emailVerified = _.get(user, 'emailVerified');
  return emailVerified;
};

const GATSBY_AUTH_ORIGIN = process.env.GATSBY_AUTH_ORIGIN;

const AUTH_CLAIM_KEY = 'https://hasura.io/jwt/claims';
const AUTH_ROLE_KEY = 'x-hasura-allowed-roles';

const allIframes = {};

const ensureIframe = (authorizeUrl, eventOrigin) => {
  const iframeId = 'uzauthframe';
  if (!allIframes[iframeId]) {
    allIframes[iframeId] = createPromise();
    let iframe = window.document.getElementById(iframeId);
    if (!iframe) {
      // init iframe
      iframe = window.document.createElement('iframe');
      iframe.setAttribute('width', '0');
      iframe.setAttribute('height', '0');
      iframe.setAttribute('id', iframeId);
      iframe.style.display = 'none';
      const onLoad = (e) => {
        if (_.get(e, 'data.type') === 'onload') {
          allIframes[iframeId].resolve(iframe);
          window.removeEventListener('message', onLoad, false);
        }
      };
      window.addEventListener('message', onLoad, false);

      iframe.onload = onLoad;
      window.document.body.appendChild(iframe);
      iframe.setAttribute('src', authorizeUrl);
    }
  }
  return allIframes[iframeId].promise;
};

const isInIframe = () => {
  return window.location !== window.parent.location;
};

const isAuthApp = () => {
  return !!process.env.GATSBY_IS_AUTH_APP;
};

const isCsrfEnable = () => {
  return false;
};

const runIframe = async (authorizeUrl, eventOrigin, data) => {
  const iframe = await ensureIframe(authorizeUrl, eventOrigin);
  const handlers = {};
  const rtn = createPromise({
    timeoutMs: 10 * 1000,
    finallyCb: () => {
      window.removeEventListener('message', handlers.iframeEventHandler, false);
    },
  });

  // config listeners
  handlers.iframeEventHandler = (e) => {
    if (e.origin !== eventOrigin) return;
    if (_.get(e, 'data.request')) return;

    if (e.source) e.source.close();

    if (e.data.response && e.data.response.error) {
      rtn.reject(e.data.response);
    } else {
      rtn.resolve(e.data.response);
    }
  };
  window.addEventListener('message', handlers.iframeEventHandler, false);

  // send post message to iframe
  iframe.contentWindow.postMessage(
    {
      request: true,
      ...data,
    },
    '*'
  );
  return rtn.promise;
};

const createQueryParams = (params) => qs.stringify(params);

const INIT_SYM = {};

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            firebase: hook((ctx) => {
              const firebase = getFirebase();
              return firebase;
            }),
            authModel: hook((ctx) => {
              if (!isBrowser()) return {};
              const ref = React.useRef({});
              const route = useRoute();

              const [cUser, $cUser] = React.useState(null);
              const [currentUser, $currentUser] = useLocalStorage('@NA::currentUser', getFirebase().auth().currentUser);
              const userId = _.get(currentUser, 'uid');

              const [authTokens, $authTokens] = useLocalStorage('@NA::authTokens', INIT_SYM);
              const [apiToken, $apiToken] = useLocalStorage('@NA::apiToken', '');
              const [claims, $claims] = React.useState({});
              const [syncAuth, $syncAuth] = React.useState(false);

              const [initing, $initing] = React.useState(() => {
                if (isAuthApp()) return false;
                return !userId;
              });
              // const [redirectResultFlag, $redirectResultFlag] = React.useState(true);
              const [isLoadingAuthState, $isLoadingAuthState] = React.useState(!!userId);
              const [isLoadingRedirectResult, $isLoadingRedirectResult] = React.useState(true);
              Object.assign(ref.current, {
                currentUser,
                $currentUser,
                cUser,
                $cUser,
                userId,
                authTokens,
                $authTokens,
                claims,
                $claims,
                initing,
                $initing,
                isLoadingAuthState,
                $isLoadingAuthState,
                isLoadingRedirectResult,
                $isLoadingRedirectResult,
                syncAuth,
                $syncAuth,
                apiToken,
                $apiToken,
                createUserPromise: () => {
                  ref.current.hasUserPromise = createPromise({
                    timeoutMs: 60 * 1000,
                  });
                },
              });

              React.useEffect(() => {
                ref.current.createUserPromise();
              }, []);

              React.useEffect(() => {
                modConfig.set({ HASURA_GRAPHQL_JWT_TOKEN: apiToken, WALLET_GRAPHQL_JWT_TOKEN: apiToken });
              }, [apiToken]);

              const tokenUtil = React.useMemo(
                () => ({
                  refreshToken: async (user) => {
                    const uid = _.get(user, 'uid');
                    console.log(`refresh Token for ${uid}`);
                    try {
                      // turn onloading
                      ctx.apply('loadingModel.showLoading');

                      await ctx
                        .get('firebase')
                        .functions()
                        .httpsCallable('triggers-user-refreshTokenCall')({ uid });
                      // force to reload token
                      await user.getIdToken(true);

                      await new Promise((res) => setTimeout(res, 1000));

                      const newToken = await tokenUtil.updateTokens(user);
                      return newToken;
                    } catch (err) {
                      // token refresh error. Should restart the app?
                      console.error(err);
                    } finally {
                      // turn off loading;
                      ctx.apply('loadingModel.hideLoading');
                    }
                  },
                  updateTokens: async (user) => {
                    // const token = await user.getIdToken();
                    const token = await user.getIdToken();
                    const idTokenResult = await user.getIdTokenResult();
                    const hasuraClaim = idTokenResult.claims[AUTH_CLAIM_KEY];
                    try {
                      modConfig.register(
                        'refreshToken',
                        () => ref.current.currentUser && tokenUtil.refreshToken(ref.current.currentUser)
                      );
                    } catch (err) {}

                    if (hasuraClaim) {
                      const uid = _.get(user, 'uid');
                      ref.current.$authTokens && ref.current.$authTokens({ token });
                      if (!ref.current.apiToken) {
                        const accessToken = _.get(
                          await ctx
                            .get('firebase')
                            .functions()
                            .httpsCallable('triggers-user-apiTokenCall')({ uid }),
                          'data.signinToken'
                        );
                        ref.current.$apiToken && ref.current.$apiToken(accessToken);
                      }
                      return token;
                    }
                  },
                  refreshTokenTimer: null,
                }),
                []
              );

              const auth = React.useMemo(() => {
                // Listen for authentication state to change.
                getFirebase()
                  .auth()
                  .onAuthStateChanged(async (user) => {
                    if (user) {
                      try {
                        // send email verification
                        await checkEmailVerification(user);

                        // custom hasura claims
                        // const token = await updateTokens(user);
                        const token = await tokenUtil.updateTokens(user);
                        if (!token) {
                          // try to refresh token with claims
                          // call refreshToken service
                          tokenUtil.refreshToken(user);
                        }
                        const idTokenResult = await user.getIdTokenResult();
                        ref.current.$claims(_.get(idTokenResult, 'claims'));
                        ref.current.$currentUser(user);
                        ref.current.$cUser(user);
                        ref.current.$isLoadingAuthState(false);
                        ref.current.hasUserPromise.resolve(true);
                        ref.current.$initing(false);
                      } catch (err) {
                        console.log('error', err);
                      }
                    } else {
                      ref.current.$currentUser(user);
                      ref.current.$cUser(user);
                      ref.current.$authTokens(null);
                      ref.current.hasUserPromise.resolve(null);
                    }
                  });
                const routeParams = _.get(route, 'params', {});
                const redirectUrl = _.get(routeParams, 'redirect_url') || process.env.GATSBY_APP_ORIGIN;
                if (redirectUrl) {
                  getFirebase()
                    .auth()
                    .getRedirectResult()
                    .then(async (result) => {
                      const user = _.get(result, 'user');
                      console.log({ getRedirectResult: user });
                      if (user) {
                        await authModel.onLoginRedirect();
                      }
                      $isLoadingRedirectResult(false);
                    });
                } else {
                  $isLoadingRedirectResult(false);
                }

                return getFirebase().auth;
                // eslint-disable-next-line
              }, [ref]);

              const envRef = React.useRef(null);
              const getEnv = async () => {
                if (!envRef.current) {
                  const promise = new Promise((res, rej) => {
                    envRef.current = { res, rej };
                  });
                  Object.assign(envRef.current, { promise });
                  const env = await getFirebase()
                    .database()
                    .ref(`/config/${process.env.REACT_NATIVE_APP_NAME}/env`)
                    .once('value');
                  envRef.current.res(env.val());
                }
                return envRef.current.promise;
              };

              const authModel = {
                firebase: getFirebase(),
                auth,
                getEnv,
                dbh: getFirebase().firestore(),
                db: getFirebase().database(),
                functions: getFirebase().functions(),
                // remoteConfig,

                currentUser: ref.current.currentUser,
                isLoadingRedirectResult,
                hasRole: (...roles) => {
                  const claimRoles = _.get(ref.current.claims, [AUTH_CLAIM_KEY, AUTH_ROLE_KEY], []);
                  for (let role of roles) {
                    if (_.includes(claimRoles, role)) {
                      return true;
                    }
                  }
                },
                isMod: () => {
                  return authModel.hasRole('mod');
                },
                setTargetProfileId: (id) => {
                  ref.current.profileId = id;
                },
                getTargetProfileId: () => {
                  return ref.current.profileId || authModel.getUserId();
                },

                getUserId: () => {
                  return _.get(ref.current.currentUser, 'uid');
                },
                getUserEmail: () => {
                  return _.get(ref.current.currentUser, 'email');
                },
                getIdToken: async () => {
                  const hasUser = await ref.current.hasUserPromise.promise;
                  if (!hasUser) return;
                  return getFirebase()
                    .auth()
                    .currentUser.getIdToken(true);
                },

                getSigninToken: async () => {
                  const hasUser = await ref.current.hasUserPromise.promise;
                  if (!hasUser) return;

                  const res = await fibGatsbyFns.getClient().post('triggers-user-signinToken', {
                    uid: authModel.getUserId(),
                  });

                  return _.get(res, 'signinToken');
                },

                isAuthenticated: () => {
                  return !!ref.current.currentUser;
                },

                isVerifiedUser: () => {
                  return checkEmailVerification(ref.current.currentUser) === true;
                },

                updatePassword: (currentPassword, newPassword) => {
                  ref.current.currentUser
                    .reauthenticateWithCredential(
                      getFirebase().auth.EmailAuthProvider.credential(ref.current.currentUser.email, currentPassword)
                    )
                    .then(() => {
                      ref.current.currentUser
                        .updatePassword(newPassword)
                        .then(() => {
                          return {
                            code: 0,
                            detail: ctx.apply('i18n.t', 'Profile.updatePass', 'Update password'),
                            message: ctx.apply('i18n.t', 'Profile.updatePassSuccess', 'Update password successfully!'),
                          };
                        })
                        .catch((error) => {
                          throw Error(_.get(error, 'message', ''));
                        });
                    })
                    .catch((err) => {
                      throw Error(ctx.apply('i18n.t', 'Profile.currentPassword', _.get(err, 'message', '')));
                    });
                },

                forgotPassword: (emailReset) =>
                  getFirebase()
                    .auth()
                    .sendPasswordResetEmail(emailReset)
                    .then(() => {
                      return {
                        code: 0,
                        detail: ctx.apply('i18n.t', 'Profile.sendEmail', 'Send mail'),
                        message: ctx.apply('i18n.t', 'Profile.sendEmailSuccess', 'Update password successfully!'),
                      };
                    }),

                verifyResetCode: (actionCode) => {
                  return getFirebase()
                    .auth()
                    .verifyPasswordResetCode(actionCode);
                },

                resetPassword: async (actionCode, password) => {
                  const auth = getFirebase().auth();
                  await auth.confirmPasswordReset(actionCode, password);
                },

                authorizeUrl(authorizeOptions) {
                  return `${GATSBY_AUTH_ORIGIN}/authorize?${createQueryParams(authorizeOptions)}`;
                },

                generateLoginRedirectUrl: (params, options) => {
                  const redirectUrl = route.redirectUrl(params, options);
                  const redirectParams = { redirect_url: redirectUrl };
                  // return `${GATSBY_AUTH_ORIGIN}/?${createQueryParams(redirectParams)}`;
                  return `/login?${createQueryParams(redirectParams)}`;
                },

                generateRegisterRedirectUrl: (params, options) => {
                  const redirectUrl = route.redirectUrl(params, options);
                  const redirectParams = { redirect_url: redirectUrl };
                  // return `${GATSBY_AUTH_ORIGIN}/signup?${createQueryParams(redirectParams)}`;
                  return `/signup?${createQueryParams(redirectParams)}`;
                },

                onLoginRedirect: async (reload = true) => {
                  const routeParams = _.get(route, 'params', {});
                  const redirectUrl = _.get(routeParams, 'redirect_url') || process.env.GATSBY_APP_ORIGIN;
                  await ref.current.hasUserPromise.promise;
                  if (redirectUrl) {
                    try {
                      const urlObj = new URL(redirectUrl);
                      // urlObj.searchParams.set('__sync', 1);
                      route.navigateExternal(urlObj.toString());
                    } catch (err) {
                      console.log('redirect error', err);
                    }
                  } else {
                    reload && route.reload();
                  }
                },

                onLogoutRedirect: () => {
                  const routeParams = _.get(route, 'params', {});
                  const redirectUrl = _.get(routeParams, 'redirect_url') || process.env.GATSBY_APP_ORIGIN;
                  if (redirectUrl) {
                    try {
                      const urlObj = new URL(redirectUrl);
                      urlObj.searchParams.set('__sync', 1);
                      route.navigateExternal(urlObj.toString());
                    } catch (err) {
                      console.log('redirect error', err);
                    }
                  }
                },

                ...useAsyncCallState({
                  login: async () => {
                    const loginUrl = authModel.generateLoginRedirectUrl();
                    route.navigateExternal(route.toLocale(loginUrl));
                  },
                  register: async () => {
                    const registerUrl = authModel.generateRegisterRedirectUrl();
                    route.navigateExternal(route.toLocale(registerUrl));
                  },
                  logout: async (redirect) => {
                    // await authModel.csrfLogout();
                    ref.current.currentUser &&
                      (await getFirebase()
                        .auth()
                        .signOut());
                    ref.current.$currentUser && ref.current.$currentUser(null);
                    ref.current.$cUser && ref.current.$cUser(null);
                    ref.current.$apiToken && ref.current.$apiToken('');

                    if (_.isFunction(redirect)) {
                      await redirect();
                    }
                    authModel.onLogoutRedirect();
                  },
                  emailLogin: async (username, password) => {
                    try {
                      ref.current.createUserPromise();
                      // addScope
                      await authModel.auth().signInWithEmailAndPassword(username, password);
                      await authModel.onLoginRedirect();
                    } catch (err) {
                      console.log(err);
                      throw err;
                    }
                  },
                  facebookLogin: async (redirect) => {
                    try {
                      const provider = new firebase.auth.FacebookAuthProvider();
                      ref.current.createUserPromise();
                      // addScope
                      await getFirebase()
                        .auth()
                        .signInWithPopup(provider);

                      // redirect on login success
                      if (_.isFunction(redirect)) {
                        // await redirect();
                      }
                      authModel.onLoginRedirect();
                    } catch (err) {
                      console.log(err);
                    }
                  },
                  googleLogin: async (redirect) => {
                    try {
                      const provider = new firebase.auth.GoogleAuthProvider();
                      ref.current.createUserPromise();
                      // addScope
                      const result = await getFirebase()
                        .auth()
                        .signInWithRedirect(provider);
                      if (_.isFunction(redirect)) {
                        // await redirect();
                      }

                      await authModel.onLoginRedirect();
                    } catch (err) {
                      console.log(err);
                    }
                  },

                  appleLogin: async (redirect) => {
                    try {
                      const provider = new firebase.auth.OAuthProvider('apple.com');
                      // addScope
                      provider.addScope('email');
                      provider.addScope('name');
                      ref.current.createUserPromise();
                      await getFirebase()
                        .auth()
                        .signInWithPopup(provider);
                      // // redirect on login success
                      if (_.isFunction(redirect)) {
                        // await redirect();
                      }
                      await authModel.onLoginRedirect();
                    } catch (err) {
                      console.log(err);
                    }
                  },
                  csrfLogin: async () => {
                    if (ref.current.syncAuth || !isCsrfEnable()) {
                      ref.current.$initing(false);
                      return;
                    }
                    if (!ref.current.currentUser) {
                      const url = authModel.authorizeUrl();
                      ref.current.$syncAuth(false);
                      try {
                        const codeResult = await runIframe(url, GATSBY_AUTH_ORIGIN, { type: 'csrf_request' });
                        const signin_token = _.get(codeResult, 'signin_token');
                        if (signin_token) {
                          await firebase.auth().signInWithCustomToken(signin_token);
                        } else {
                          // public user
                          ref.current.$initing(false);
                        }
                      } catch (err) {
                        console.log('err', err);
                      }
                      ref.current.$syncAuth(true);
                    }
                  },
                  csrfLogout: async () => {
                    // csrfLogout only run not in iframe
                    if (isInIframe()) return;
                    await ref.current.hasUserPromise.promise;

                    const url = authModel.authorizeUrl();
                    try {
                      await runIframe(url, GATSBY_AUTH_ORIGIN, { type: 'csrf_logout' });
                    } catch (err) {
                      console.log('err', err);
                    }
                  },
                  checkRedirectResult: async () => {
                    const routeParams = _.get(route, 'params', {});
                    const redirectUrl = _.get(routeParams, 'redirect_url') || process.env.GATSBY_APP_ORIGIN;
                    if (redirectUrl) {
                      getFirebase()
                        .auth()
                        .getRedirectResult()
                        .then((result) => {
                          const user = _.get(result, 'user');
                          if (user) {
                            authModel.onLoginRedirect();
                          }
                        });
                    }
                  },
                  init: async () => {
                    if (!ref.current.currentUser && !isInIframe() && !isAuthApp()) {
                      await authModel.csrfLogin();
                    }
                    if (!isAuthApp()) {
                      // await authModel.logout();
                    }
                  },
                }),
                initing: ref.current.initing,
                isLoadingAuthState: ref.current.isLoadingAuthState,
              };

              React.useEffect(() => {
                authModel.init();
                // eslint-disable-next-line
              }, []);
              ctx.apply('REF.setRef', 'authModel', authModel);
              return authModel;
            }),
          },
        },
      ],
    ],
  },
});
export default bindData;

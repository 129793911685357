import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';

import { Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import stripeReact from '@vl/mod-clients/stripeReact';

const StripeSeed = ({ children }) => {
  const stripe = useStripe();
  const elements = useElements();
  return (
    <DIV forceCtx>
      {ctx.debug(() => {
        ctx.set('stripe', {
          stripe,
          elements,
        });
      })}
      {children}
    </DIV>
  );
};

const StripeProvider = ({ children }) => {
  return (
    <Elements stripe={stripeReact.getClient()}>
      <StripeSeed>{children}</StripeSeed>
    </Elements>
  );
};

export default StripeProvider;

import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Select } from 'antd';
import gstyles from '@vl/gstyles';
import cx from 'classnames';
import _ from 'lodash';
import { renderPropsComposer } from '@vl/mod-utils/PropsComposer';
import styled from 'styled-components';
import StripeAddCardForm from '@uz/unitz-components-web/StripeAddCardForm';

const CustomSelect = styled(Select)`
  &.ant-select:not(.ant-select-customize-input) {
    .ant-select-selector {
      border-radius: 8px !important;
    }
  }
`;

const renderMethodATM = (item) => {
  return (
    <DIV forceCtx>
      <div key={item.id} className={cx({ 'flex flex-col ': !!_.get(item, 'banks.length') })}>
        {/* eslint-disable-next-line */}
        <div
          className="flex items-center mb-3 cursor-pointer"
          onClick={() => ctx.apply('form.selectPaymentMethod', item)}
        >
          {gstyles.icons({
            name: ctx.apply('form.hasPaymentMethod', item) ? 'radio-on' : 'radio-off',
            size: 18,
            className: 'mr-2',
          })}
          <div className="mb-0 mr-1 text-base font-normal text-main">{item.label}</div>
        </div>
        {!!_.get(item, 'banks.length') && !!ctx.apply('form.hasPaymentMethod', item) && (
          <div className="flex items-center">
            <div className="flex items-center mr-1">
              <CustomSelect
                style={{ width: 200 }}
                placeholder={ctx.apply('i18n.t', 'Payment.Method.selectBank')}
                onChange={(option) => ctx.apply('form.selectPaymentMethod', item, { bank_code: option })}
                options={item.banks}
                suffixIcon={gstyles.icons({ name: 'arrow-down', className: '-mt-1' })}
              />
            </div>
          </div>
        )}
        {!!ctx.get('form.errors.payments[1].bank_code') && (
          <div className="mt-1 text-xs text-red500">{ctx.get('form.errors.payments[1].bank_code')}</div>
        )}
      </div>
    </DIV>
  );
};

const renderMethodToken = (item) => {
  return (
    <DIV forceCtx>
      <div key={item.id} className={cx({ 'flex flex-col ': !!_.get(item, 'banks.length') })}>
        {/* eslint-disable-next-line */}
        <div
          className="flex items-center mb-3 cursor-pointer"
          onClick={() => ctx.apply('form.selectPaymentMethod', item)}
        >
          {gstyles.icons({
            name: ctx.apply('form.hasPaymentMethod', item) ? 'radio-on' : 'radio-off',
            size: 18,
            className: 'mr-2',
          })}
          <div className="mb-0 mr-1 text-base font-normal text-main">{item.label}</div>
        </div>
        {!!ctx.apply('form.hasPaymentMethod', item) && (
          <div className="flex mb-4 items-center">
            {!!_.get(item, 'banks.length') && (
              <div className="flex mr-1">
                <CustomSelect
                  style={{ width: 200 }}
                  placeholder={ctx.apply('i18n.t', 'Payment.Method.selectCard')}
                  onChange={(option) => ctx.apply('form.selectPaymentMethod', item, { card_id: option })}
                  options={item.banks}
                  suffixIcon={gstyles.icons({ name: 'arrow-down', className: '-mt-1' })}
                />
              </div>
            )}
            <StripeAddCardForm />
          </div>
        )}
        {/* {!!ctx.get('form.errors.payments[1].bank_code') && (
          <div className="mt-1 text-xs text-red500">{ctx.get('form.errors.payments[1].bank_code')}</div>
        )} */}
      </div>
    </DIV>
  );
};

const renderMethodCard = (item) => {
  return (
    <DIV forceCtx>
      <div key={item.id} className={cx({ 'flex flex-col ': !!_.get(item, 'banks.length') })}>
        {/* eslint-disable-next-line */}
        <div
          className="flex items-center mb-3 cursor-pointer"
          onClick={() => ctx.apply('form.selectPaymentMethod', item)}
        >
          {gstyles.icons({
            name: ctx.apply('form.hasPaymentMethod', item) ? 'radio-on' : 'radio-off',
            size: 18,
            className: 'mr-2',
          })}
          <div className="mb-0 mr-1 text-base font-normal text-main">{item.label}</div>
        </div>
      </div>
    </DIV>
  );
};

export const View8 = () => {
  return (
    <DIV className="paymentMethods">
      <section className="min-lg:border max-lg:pb-6 max-lg:pt-0 min-lg:p-6 min-lg:border-border rounded-xl">
        <h2 className="mb-6 max-lg:text-base min-lg:text-xl text-main">
          {ctx.apply('i18n.t', 'Payment.StepThreeTitle')}
        </h2>
        <div className="flex flex-col">
          {ctx.get('@item.lists').map((item) => (
            <React.Fragment key={item.id}>
              {renderPropsComposer(
                {
                  matcher: (props) => _.get(props, 'type') === 'payment',
                  render: renderMethodATM,
                },
                {
                  matcher: (props) => _.get(props, 'type') === 'token',
                  render: renderMethodToken,
                },
                {
                  matcher: (props) => _.get(props, 'type') === 'card',
                  render: renderMethodCard,
                },
                () => null
              )(item)}
            </React.Fragment>
          ))}
        </div>
      </section>
    </DIV>
  );
};
export default displayName(View8);

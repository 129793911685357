import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
// import _ from 'lodash';
import { ctx } from '@vl/redata';
import { Helmet } from 'react-helmet';

const View8 = ({ children }) => {
  return (
    <DIV className="component 779BD3C8-CDF6-4067-A5A5-C1B320DFF758">
      {/* <Helmet>
        <script>
          {ctx.debug(() =>
            (function(h, o, t, j, a, r) {
              h.hj =
                h.hj ||
                function() {
                  (h.hj.q = h.hj.q || []).push(arguments);
                };
              h._hjSettings = { hjid: 3381537, hjsv: 6 };
              a = o.getElementsByTagName('head')[0];
              r = o.createElement('script');
              r.async = 1;
              r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
              a.appendChild(r);
            })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')
          )}
        </script>

        <script async src="https://www.googletagmanager.com/gtag/js?id=G-Q5M7R3WF6B"></script>
        <script>
          {ctx.debug(() => {
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments);
            }
            gtag('js', new Date());

            gtag('config', 'G-Q5M7R3WF6B');
          })}
        </script>
        <script>
          {ctx.debug(() =>
            (function(w, d, s, l, i) {
              w[l] = w[l] || [];
              w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
              var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s),
                dl = l != 'dataLayer' ? '&l=' + l : '';
              j.async = true;
              j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
              f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-TZ9TH2D')
          )}
        </script>
      </Helmet>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-TZ9TH2D"
          height="0"
          width="0"
          style="display:none;visibility:hidden"
        ></iframe>
      </noscript> */}
      {children}
    </DIV>
  );
};

export default displayName(View8);

import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Modal, Button, Alert } from 'antd';
import { Form } from 'formik-antd';
import FormProvider from '@uz/unitz-components-web/FormProvider';

export default function StripeAddCardForm() {
  // useEffect(() => {
  //   // Create PaymentIntent as soon as the page loads
  //   window
  //     .fetch('/create-payment-intent', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ items: [{ id: 'xl-tshirt' }] }),
  //     })
  //     .then((res) => {
  //       return res.json();
  //     })
  //     .then((data) => {
  //       setClientSecret(data.clientSecret);
  //     });
  // }, []);

  const cardStyle = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#32325d',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  return (
    <DIV className="stripeAddCardForm">
      <Button id="card-button" className="mx-4" onClick={ctx.get('addCardForm.showModal')}>
        {ctx.apply('i18n.t', 'Payment.Method.newCard')}
      </Button>
      <Modal
        title={ctx.apply('i18n.t', 'Payment.Method.newCard')}
        visible={ctx.get('addCardForm.isModalVisible')}
        onOk={ctx.get('addCardForm.handleSubmit')}
        onCancel={ctx.get('addCardForm.handleCancel')}
        okButtonProps={{
          type: 'primary',
          htmlType: 'submit',
          id: 'submit',
          disabled: !ctx.get('addCardForm.canSubmit'),
          loading: ctx.get('addCardForm.isSubmitting'),
          className: 'sm:w-20 sm:h-8 md:w-20 md:h-14 lg:w-20 lg:h-14 xl:w-20 xl:h-14 2xl:w-20 2xl:h-14',
        }}
        okText={ctx.apply('i18n.t', 'Payment.Method.newCard')}
      >
        {ctx.get('addCardForm.isModalVisible') && (
          <FormProvider form={ctx.get('addCardForm')}>
            <Form className="payment-form" id="payment-form">
              <CardElement id="card-element" options={cardStyle} onChange={ctx.get('addCardForm.handleChange')} />
              {/* Show any error that happens when processing the payment */}
              {ctx.get('addCardForm.status.error') && (
                <div className="py-2">
                  <Alert message={ctx.get('addCardForm.status.error.message')} type="error" />
                </div>
              )}
              {/* Show a success message upon completion */}
              {/* <p className={ctx.get('addCardForm.succeeded') ? 'result-message' : 'result-message hidden'}>
                Payment succeeded, see the result in your
              </p> */}
            </Form>
          </FormProvider>
        )}
      </Modal>
    </DIV>
  );
}

import React from 'react';

// load web config
import '@vl/mod-config/web';

// load web translation
import '@uz/mod-translations/web';

import Providers from '@uz/unitz-providers/Providers';
import RefProvider from '@uz/unitz-providers/RefProvider';
import RouteProvider from '@uz/unitz-providers/RouteWebProvider';
import I18nProvider from '@uz/unitz-providers/I18nProvider';
import I18nWebProvider from '@uz/unitz-providers/I18nWebProvider';
import ValidateProvider from '@uz/unitz-providers/ValidateProvider';
import AuthProvider from '@uz/unitz-providers/AuthWebProvider';
import UserProvider from '@uz/unitz-providers/UserWebProvider';
import LayoutProvider from '@uz/unitz-providers/LayoutWebProvider';
import TopupProvider from '@uz/unitz-providers/TopupProvider';
import RedeemProvider from '@uz/unitz-providers/RedeemProvider';
import MessageProvider from '@uz/unitz-providers/MessageWebProvider';
import TrackingProvider from '@uz/unitz-providers/TrackingProvider';
import ShareLinkProvider from '@uz/unitz-providers/ShareLinkProvider';

import LoadingOverlay from '@uz/unitz-components-web/LoadingOverlay';

const UserApp = ({ children }) => (
  <LoadingOverlay>
    <Providers
      providers={[
        RefProvider,
        I18nProvider,
        I18nWebProvider,
        MessageProvider,
        ValidateProvider,
        AuthProvider,
        LayoutProvider,
        UserProvider,
        RouteProvider,
        TopupProvider,
        RedeemProvider,
        TrackingProvider,
        ShareLinkProvider,
      ]}
    >
      {children}
    </Providers>
  </LoadingOverlay>
);

export default UserApp;

import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';

const bindData = bindings({
  trackingModel: {
    rules: [
      [
        'data',
        {
          data: {
            tracking: hook(() => {
              return {
                dataSet: (dataObj) => {
                  const result = {};
                  if (_.isObject(dataObj)) {
                    const dataKeys = {
                      label: 'data-label',
                      value: 'data-value',
                      category: 'data-category',
                      action: 'data-action',
                    };
                    _.map(_.keys(dataObj), (key) => {
                      if (_.has(dataObj, key) && _.has(dataKeys, key)) {
                        _.set(result, dataKeys[key], dataObj[key]);
                      }
                    });
                  }
                  return result;
                },
                event: (fnCb, fnDataSet) => (event) => {
                  if (_.isFunction(fnCb)) {
                    fnCb(event);
                  }
                  if (_.isFunction(fnDataSet)) {
                    fnDataSet(event);
                  }
                },
              };
            }),
          },
        },
      ],
    ],
  },
});
export default bindData;

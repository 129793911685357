import React from 'react';
import RedeemModal from '@uz/unitz-components-web/RedeemModal';
import useRoute from '@vl/hooks/useGbRoute';
import _ from 'lodash';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';

const RedeemProvider = ({ children }) => {
  const route = useRoute();
  const [showRedeem, $showRedeem] = React.useState();
  return (
    <DIV>
      {ctx.debug(() => {
        const redeemModel = {
          show: () => {
            $showRedeem(true);
          },
          hide: () => {
            $showRedeem(false);
          },
        };
        ctx.set('redeemModel', redeemModel);
      })}
      {(!!_.get(route, 'params.redeem') || showRedeem) && <RedeemModal />}
      {children}
    </DIV>
  );
};

export default RedeemProvider;

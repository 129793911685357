import moment from 'moment';
import _ from 'lodash';
import currencyFormatter, { formatVND } from '@vl/mod-utils/currencyFormatter';
import { formatDateDisplay } from '@vl/mod-utils/dateFormatter';

// amount: 400000
// code: "UZ-KVQNZ"
// currency: "vnd"
// id: "b007cd59-ef90-49ac-851a-79c614ecc59d"
// is_active: true
// type_id: "random_code"
// valid_after: "2021-08-15T14:44:46.172+00:00"
// valid_before: "2021-08-22T14:44:46.172+00:00"
// valid_for: null
// valid_usage_count: 1
// valid_user_id: null
export const formatter = {
  code: (ctx) => (itemData) => {
    const code = _.get(itemData, 'code', '');
    if (!code) return '';
    return code;
  },

  price: (ctx) => (itemData) => {
    const price_amount = _.get(itemData, 'amount');
    if (!price_amount) return 0;
    return currencyFormatter(ctx)(parseInt(price_amount));
  },
  priceTxt: (ctx) => (itemData) => {
    const price_amount = _.get(itemData, 'amount');
    if (!price_amount) return 0;
    return formatVND(price_amount);
  },
  validBefore: (ctx) => (itemData) => {
    const createdAt = _.get(itemData, 'valid_before', '');
    if (!createdAt) return '';
    return formatDateDisplay(createdAt);
  },
  validAfter: (ctx) => (itemData) => {
    const createdAt = _.get(itemData, 'valid_after', '');
    if (!createdAt) return '';
    return formatDateDisplay(createdAt);
  },
};

export default formatter;

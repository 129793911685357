import _ from 'lodash';
import graphme from '@unitz/graphme';

import walletClient from '@vl/mod-clients/walletApp';

import GetCard from 'unitz-gql/cardwallet/GetCard.gql';

graphme.GqlBuilder.loadDocument({
  GetCard,
});

class CardWalletModel extends graphme.BaseModel {
  static DEFINITION = graphme.Definition.create({
    name: 'Transaction',
    schema: {
      id: String,
      card_type: String,
      card_number: String,
      is_default: Boolean,
      bank_code: String,
    },
    nodes: [],
    key: 'id',

    baseQuery: 'GetCard',

    GQL_ACTIONS: _.memoize(() => {
      return {
        GET: 'card_by_pk',
        FIND: 'card',
        INSERT: 'insert_card_one',
        INSERT_MANY: 'insert_card',
        UPDATE: 'update_card_by_pk',
        DELETE: 'delete_card_by_pk',
        DELETE_MANY: 'delete_card',
      };
    })(),

    getClient: walletClient.getClient,
  });

  static async removeCard({ id }) {
    const query = walletClient.gql`
      mutation updateProfile($id: uuid!) {
        card: delete_card_by_pk(id: $id) {
          id
          is_active
        }
      }
    `;
    try {
      const rtn = await walletClient.getClient().request(query, {
        id,
      });

      const data = _.get(rtn, 'card.id');
      if (data) {
        return data;
      }
    } catch (err) {
      console.log(err);
    }

    return null;
  }
}

graphme.model({ CardWalletModel });

export default CardWalletModel;

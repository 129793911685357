import React from 'react';

import { bindings, hook } from '@vl/redata';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import _ from 'lodash';

const bindData = bindings({
  shareLinkModal: {
    rules: [
      [
        'data',
        {
          data: {
            item: hook((ctx) => {
              return {
                facebook: {
                  quote: ctx.apply('i18n.t', 'Course.shareContent'),
                  hashtag: ['#unitz', '#unitzcourse', '#unitzedu'].join(''),
                  url: ctx.get('shareLinkModel.options.shareUrl'),
                },
                twitter: {
                  title: ctx.apply('i18n.t', 'Course.shareContent'),
                  via: 'UnitzHQ',
                  hashtags: ['unitz', 'unitzcourse', 'unitzedu'],
                  url: ctx.get('shareLinkModel.options.shareUrl'),
                },
                linkedin: {
                  url: ctx.get('shareLinkModel.options.shareUrl'),
                },
                email: {
                  body: ctx.apply('i18n.t', 'Course.shareContent'),
                  url: ctx.get('shareLinkModel.options.shareUrl'),
                },
              };
            }),
            form: hook((ctx) => {
              const [isModalVisible, setIsModalVisible] = React.useState(true);
              const form = useFormik({
                initialValues: {
                  shareUrl: ctx.get('shareLinkModel.options.shareUrl'),
                },
                onSubmit: async (values, actions) => {
                  // console.log({ values, actions });
                },
                validationSchema: Yup.object().shape({}),
              });

              return {
                ...form,
                showModal: () => {
                  setIsModalVisible(true);
                },
                handleCancel: () => {
                  setIsModalVisible(false);
                },
                isModalVisible,
                copyLink: () => {
                  navigator.clipboard.writeText(form.values.shareUrl);
                },
              };
            }),
            canSubmit: hook((ctx) => {
              return ctx.get('form.isValid') && !ctx.get('form.isSubmitting') && ctx.get('form.dirty');
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;

import React from 'react';
import { resable } from '@vl/redata/RESABLE';
import RESABLE from '@vl/redata/RESABLE.macro';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Modal, Alert } from 'antd';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';
import { Input, Form, FormItem } from 'formik-antd';

const CustomInput = styled.div`
  textarea,
  input {
    border-radius: 8px;
    border-color: ${gstyles.colors.divider};
    &::placeholder {
      color: ${gstyles.colors.placeHolder};
      font-size: 16px;
    }
  }
`;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export default function RedeemModal() {
  return (
    <DIV className="redeemModal">
      <Modal
        title={ctx.apply('i18n.t', 'Wallet.Action.addVoucher')}
        visible={ctx.get('form.isModalVisible')}
        onOk={ctx.get('form.handleSubmit')}
        onCancel={ctx.get('form.handleCancel')}
        okButtonProps={{
          type: 'primary',
          htmlType: 'submit',
          id: 'submit',
          disabled: !ctx.get('form.canSubmit'),
          loading: ctx.get('form.isSubmitting'),
          className: 'px-8',
        }}
        okText={ctx.apply('i18n.t', 'Wallet.Action.addVoucher')}
        destroyOnClose
        afterClose={() => {
          ctx.apply('redeemModel.hide');
        }}
      >
        {ctx.get('form.isModalVisible') && (
          <FormProvider form={ctx.get('form')}>
            <Form {...layout} className="redeem-form" id="redeem-form">
              <FormItem name="code">
                <CustomInput>
                  <Input size="medium" name="code" placeholder={ctx.apply('i18n.t', 'Voucher.codePlaceholder')} />
                </CustomInput>
              </FormItem>
            </Form>
          </FormProvider>
        )}
      </Modal>
    </DIV>
  );
}

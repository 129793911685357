import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import useObservableSource from '@vl/hooks/useObservableSource';

import useRoute from '@vl/hooks/useGbRoute';

import PurchaseModelFormatter from '@uz/unitz-models/PurchaseModel/formatter';
import PurchaseModel from '@uz/unitz-models/PurchaseModel';

const bindData = bindings({
  topupConfirm: {
    rules: [
      [
        'data',
        {
          data: {
            '@items': hook((ctx) => {
              const purchaseData = ctx.get('purchaseData');
              return {
                status: PurchaseModelFormatter.getStatus(ctx)(purchaseData),
                purchaseData,
              };
            }),
          },
        },
      ],
    ],
  },
  forPurchaseView: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => {
            const route = useRoute();
            const routeParams = _.get(route, 'params', {});
            const purchaseId = _.get(routeParams, 'purchase_id');
            return !!purchaseId;
          }),
        },
      ],
    ],
  },

  purchaseData: {
    rules: [
      [
        'data',
        {
          data: {
            purchaseData: hook(() => {
              const route = useRoute();
              const routeParams = _.get(route, 'params', {});
              const purchaseId = _.get(routeParams, 'purchase_id');
              const items = useObservableSource(() => {
                return PurchaseModel.find(
                  `where: {id: {_eq: "${purchaseId}"}}`,
                  `
                      id
                      payments {
                        amount
                        type
                      }
                      services {
                        id
                        price_amount
                        advisor_service {
                          id
                          service_id
                          advisor {
                            id
                            profile {
                              display_name
                              avatar_url
                            }
                            review_agg {
                              avg_score
                            }
                            reviews_aggregate {
                              aggregate { count }
                            }
                          }
                          service { id name kind }
                          price {
                            price_amount
                            price_currency
                            per_unit
                            per_amount
                          }
                          discounts {
                            percentage
                            currency
                            id
                            amount
                            type
                          }  
                        }
                      }
                      status {
                        status
                      }
                      status_latest {
                        status
                      }
                    `
                );
              });
              return items;
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;

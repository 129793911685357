import React from 'react';
import ShareLinkModal from '@uz/unitz-components-web/ShareLinkModal';
import useRoute from '@vl/hooks/useGbRoute';
import _ from 'lodash';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';

const ShareLinkProvider = ({ children }) => {
  const [showShareLink, $showShareLink] = React.useState();
  const [options, $options] = React.useState({});
  return (
    <DIV>
      {ctx.debug(() => {
        const shareLinkModel = {
          options,
          open: ({ title, shareUrl, content }) => {
            $options({ title, shareUrl, content });
            $showShareLink(true);
          },
          hide: () => {
            $showShareLink(false);
          },
        };
        ctx.set('shareLinkModel', shareLinkModel);
      })}
      {!!showShareLink && <ShareLinkModal />}
      {children}
    </DIV>
  );
};

export default ShareLinkProvider;

import completed from './Completed';
import pending from './Pending';
import reject from './Reject';
import unknown from './Unknown';

const iconStatus = {
  completed,
  pending,
  reject,
  unknown
};
export default iconStatus;

import React from 'react';
import cx from 'classnames';
import displayName from '@vl/redata/displayName.macro';

const View8 = (props) => {
  const prop = props || {};
  return (
    <div {...prop} className={cx('flex items-center', { 'cursor-pointer': !!prop.onClick })}>
      <span className="w-4 h-4 mr-2 leading-4 text-center rounded-full bg-sub text-white500">?</span>
      {prop.name}
    </div>
  );
};
export default displayName(View8);

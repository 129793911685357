const { GraphQLClient } = require('graphql-request');
const _ = require('lodash');
const modConfig = require('@vl/mod-config');
const { gql } = require('graphql-request');
const { SubscriptionClient } = require('graphql-subscriptions-client');
const { isSSR, isWorker, subscriptionSSR } = require('@vl/mod-utils/platform');
require('@vl/polyfill');

const getOptions = () => {
  const options = {
    endpoint: modConfig.get('WALLET_GRAPHQL_ENDPOINT'),
    adminSecret: modConfig.get('WALLET_GRAPHQL_ADMIN_SECRET'),
    jwtToken: modConfig.get('WALLET_GRAPHQL_JWT_TOKEN'),
    debug: true,
  };
  return options;
};

const JTW_TOKEN_EXPIRED_MSG = 'Could not verify JWT: JWTExpired';

const isTokenExpireError = (err) => {
  const found = `${_.get(err, 'message') || err}`.indexOf(JTW_TOKEN_EXPIRED_MSG);
  return found === 0;
};

const getHeaders = (options) => {
  const headers = {};
  if (options.jwtToken) {
    _.assign(headers, {
      Authorization: `Bearer ${options.jwtToken}`,
    });
  } else if (options.adminSecret) {
    _.assign(headers, {
      'x-hasura-admin-secret': options.adminSecret,
    });
  }
  return headers;
};

const getClientSubs = _.memoize((endpoint, opts = {}) => {
  const options = getOptions();

  endpoint = endpoint || options.endpoint;

  const WS_ENDPONT = `${endpoint}`.replace(/^http/, 'ws');

  const rtn = {};
  // set up the client, which can be reused
  const clientOptions = {
    reconnect: true,
    lazy: true, // only connect when there is a query

    connectionCallback: async (error) => {
      if (error) {
        console.log('subscription error', error);
        if (isTokenExpireError(error)) {
          const refreshToken = modConfig.resolve('refreshToken');
          if (_.isFunction(refreshToken)) {
            await refreshToken();
          }
          // reupdate connection params
          rtn.client.sendMessage &&
            rtn.client.sendMessage(undefined, 'connection_init', clientOptions.connectionParams());
          rtn.client.connect && rtn.client.connect();
          // return;
        }
      }
    },

    connectionParams: () => {
      const connParams = {
        headers: getHeaders({
          ...getOptions(),
        }),
      };
      return connParams;
    },
  };

  const client = new SubscriptionClient(WS_ENDPONT, clientOptions);

  client.on('error', (error) => {
    console.log('wallet sub Error', error);
    // rtn.client.connect && rtn.client.connect();
  });

  rtn.client = client;
  return rtn.client;
});

exports.getClient = _.memoize((endpoint, opts = {}) => {
  const options = getOptions();

  endpoint = endpoint || options.endpoint;

  const clientOptions = {
    endpoint,
    headers: getHeaders(getOptions()),
  };

  const client = new GraphQLClient(clientOptions.endpoint, {
    headers: clientOptions.headers,
  });

  _.set(client, 'options', clientOptions);

  const rtn = { client };
  // check for debug mode
  if (_.get(options, 'debug', true)) {
    rtn.client = new Proxy(client, {
      get(obj, prop) {
        if (prop === 'options') {
          return clientOptions;
        }
        if (prop === 'request') {
          return async function(...args) {
            // console.log('request with args:', ...args);
            if (isSSR() && !isWorker()) {
              return null;
            }
            const reqHeaders = _.cloneDeep(clientOptions.headers);
            const newHeaders = getHeaders(getOptions());
            if (!_.isEqual(newHeaders, reqHeaders)) {
              client.setHeaders(newHeaders);
              clientOptions.headers = newHeaders;
            }
            try {
              const rtn = await obj.request(...args);
              return rtn;
            } catch (err) {
              if (isTokenExpireError(err)) {
                const refreshToken = modConfig.resolve('refreshToken');
                if (_.isFunction(refreshToken)) {
                  await refreshToken();
                }
              }
              // retry if headers changed
              const newHeaders = getHeaders(getOptions());
              if (!_.isEqual(newHeaders, reqHeaders)) {
                // header changed, use new headers instead
                client.setHeaders(newHeaders);
                clientOptions.headers = newHeaders;
                return new Promise((res, rej) => {
                  setTimeout(async () => {
                    try {
                      const retryRes = await rtn.client.request(...args);
                      res(retryRes);
                    } catch (err) {
                      rej(err);
                    }
                  }, 300);
                });
              }
              throw err;
            }
          };
        }
        if (prop === 'subscribe') {
          return function(...args) {
            const [query, variables] = args;
            // console.log('susbcribe with query:', query, isWorker(), isSSR());
            if (isSSR() && !isWorker()) {
              return subscriptionSSR();
            }
            const subject = getClientSubs().request({ query, variables });
            return subject;
          };
        }
      },
    });
  }
  return rtn.client;
});

exports.gql = gql;

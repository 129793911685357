import React from 'react';
import { resable } from '@vl/redata/RESABLE';
import RESABLE from '@vl/redata/RESABLE.macro';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Modal, Alert } from 'antd';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import StripeProvider from '@uz/unitz-providers/StripeProvider';
import TopupMethodsSection from '@uz/unitz-components-web/TopupMethodsSection';
import TopupConfirm from '@uz/unitz-components-web/TopupConfirm';
import { Input, Form, FormItem } from 'formik-antd';
import UserWalletModelFormatter from '@uz/unitz-models/UserWalletModel/formatter';
import InputCurrency from '@uz/unitz-components-web/InputCurrency';
import styled from 'styled-components';
import gstyles from '@vl/gstyles';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const CustomInputCurrency = styled(InputCurrency)`
  &:not(.ant-select-disabled):hover {
    .ant-select-selector {
      border: 0;
    }
  }
  .ant-input-affix-wrapper {
    border-radius: 8px;
    border-color: ${gstyles.colors.border};
  }
`;

export default function TopupModal() {
  return (
    <StripeProvider>
      <DIV className="topupModal">
        <Modal
          title={ctx.apply('i18n.t', 'UserPayment.topUpTxt')}
          visible={ctx.get('form.isModalVisible')}
          onOk={ctx.get('form.handleSubmit')}
          onCancel={ctx.get('form.handleCancel')}
          okButtonProps={{
            type: 'primary',
            htmlType: 'submit',
            id: 'submit',
            disabled: !ctx.get('form.canSubmit'),
            loading: ctx.get('form.isSubmitting'),
            className: 'px-8',
          }}
          okText={ctx.apply('i18n.t', 'UserPayment.topUpTxt')}
          destroyOnClose
          afterClose={() => {
            ctx.apply('topupModel.hide');
          }}
        >
          {ctx.get('form.isModalVisible') && (
            <FormProvider form={ctx.get('form')}>
              <Form {...layout} className="topup-form" id="topup-form">
                <DIV className="processingStep">
                  <TopupConfirm />
                </DIV>
                <DIV className="topupStep">
                  <RESABLE>
                    <DIV>
                      {ctx.debug(() => {
                        const myWallets = resable.for('myWallets')(ctx.get('myWallets'), null) || [];
                        ctx.set('myWallets', myWallets);
                      })}
                      <div className="text-base font-normal max-lg:text-sm text-sub text-center p-4">
                        (
                        {`${ctx.apply('i18n.t', 'AdvisorWallet.walletBalance')} ${UserWalletModelFormatter.available(
                          ctx
                        )(ctx.get('myWallets.0.wallets'))}`}
                        )
                      </div>
                    </DIV>
                  </RESABLE>

                  <FormItem name="amount" label={ctx.apply('i18n.t', 'UserPayment.amount')}>
                    <CustomInputCurrency
                      size="large"
                      name="amount"
                      placeholder={ctx.apply('i18n.t', 'UserPayment.amount')}
                      suffix="đ"
                    />
                    {/* <Input className="rounded-lg" name="amount" placeholder={'Amount'} size="large" addonAfter="vnd" /> */}
                  </FormItem>
                  <RESABLE>
                    <DIV>
                      {ctx.debug(() => {
                        const cards = resable.for('cards')(ctx.get('cards'), []) || [];
                        ctx.set('cards', cards);
                      })}
                      <TopupMethodsSection />
                    </DIV>
                  </RESABLE>

                  {ctx.get('form.status.error') && (
                    <div className="py-2">
                      <Alert message={ctx.get('topupForm.status.error.message')} type="error" />
                    </div>
                  )}
                </DIV>
              </Form>
            </FormProvider>
          )}
        </Modal>
      </DIV>
    </StripeProvider>
  );
}

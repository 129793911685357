import React from 'react';
import TopupModal from '@uz/unitz-components-web/TopupModal';
import useRoute from '@vl/hooks/useGbRoute';
import _ from 'lodash';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';

const TopupProvider = ({ children }) => {
  const route = useRoute();
  const [showTopup, $showTopup] = React.useState();
  return (
    <DIV>
      {ctx.debug(() => {
        const topupModel = {
          show: () => {
            $showTopup(true);
          },
          hide: () => {
            $showTopup(false);
          },
        };
        ctx.set('topupModel', topupModel);
      })}
      {(!!_.get(route, 'params.topup') || showTopup) && <TopupModal />}
      {children}
    </DIV>
  );
};

export default TopupProvider;

import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import useRoute from '@vl/hooks/useGbRoute';

const bindData = bindings({
  routeProvider: {
    rules: [
      [
        'data',
        {
          data: {
            routeStore: hook((ctx) => {
              const route = useRoute();
              return {
                isUnitzVN: () => {
                  const GATSBY_TARGET_DOMAIN = process.env.GATSBY_TARGET_DOMAIN;
                  return GATSBY_TARGET_DOMAIN === 'unitz.vn';
                },
                isUnitzApp: () => {
                  const GATSBY_TARGET_DOMAIN = process.env.GATSBY_TARGET_DOMAIN;
                  return GATSBY_TARGET_DOMAIN === 'unitz.app';
                },
                getNavigation: () => {
                  return {
                    ...route,
                    navigate: () => {},
                    replace: () => {},
                  };
                },
                ...route,
              };
            }),
            routes: hook((ctx) => {
              const routeStore = ctx.get('routeStore');
              const routes = {
                goBack: () => routeStore.getNavigation().goBack(),

                Signup: () => routeStore.getNavigation().navigate('Signup'),
                Login: () => routeStore.getNavigation().navigate('Login'),
                Forgot: () => routeStore.getNavigation().navigate('Forgot'),
                Explore: () => {
                  routeStore.getNavigation().navigate('Home');
                },
                PrivacyPolicy: () => routeStore.getNavigation().navigate('PrivacyPolicy'),

                AdvisorService: (item, advisor) => {
                  const name = _.get(item, 'name');
                  name === 'ChatService' && routeStore.getNavigation().navigate('AdvisorChat', advisor);
                  name === 'PhoneService' && routeStore.getNavigation().navigate('AdvisorPhoneCallStart', advisor);
                  name === 'VideoService' &&
                    routeStore.getNavigation().replace('AdvisorVideoStart', { serviceSelected: item, ...advisor });
                },

                AdvisorProfile: (item) => routeStore.getNavigation().navigate('AdvisorProfile', item),
                AdvisorList: () => routeStore.getNavigation().navigate('AdvisorList'),
                AdvisorChat: () => routeStore.getNavigation().navigate('AdvisorChat'),
                Advisor_ChatService: (item) => routeStore.getNavigation().navigate('AdvisorChat', item),
                AdvisorVideoCall: (item) => {
                  routeStore.getNavigation().replace('AdvisorVideoCall', item);
                },
                Advisor_VideoService: (item) => routeStore.getNavigation().navigate('AdvisorVideoStart', item),
                AdvisorVideoFinish: () => routeStore.getNavigation().replace('AdvisorVideoFinish'),

                AdvisorVideoIncoming: (params) => routeStore.getNavigation().navigate('AdvisorVideoIncoming', params),
                AdvisorBookingMethod: (params) => routeStore.getNavigation().navigate('AdvisorBookingMethod', params),
                AdvisorPhoneCall: () => routeStore.getNavigation().navigate('AdvisorPhoneCall'),
                Advisor_PhoneService: (item) => routeStore.getNavigation().navigate('AdvisorPhoneCall', item),

                CategoryDetail: (item) =>
                  routeStore.getNavigation().navigate('CategoryDetail', { cat_id: _.get(item, 'id'), ...item }),

                ProfileEdit: (item) =>
                  routeStore.getNavigation().navigate('ProfileEdit', { user_id: _.get(item, 'id') }),
                ProfilePassword: (item) =>
                  routeStore.getNavigation().navigate('ProfilePassword', { user_id: _.get(item, 'id') }),

                PaymentEditCard: (params) => routeStore.getNavigation().navigate('PaymentEditCard', params),
                AdvisorBooking: (params) =>
                  routeStore.getNavigation().navigate('Booking', { params, screen: 'AdvisorBooking' }),
                PaymentSuccess: (params) => routeStore.getNavigation().replace('PaymentSuccess', params),
                SearchResult: (params) => routeStore.getNavigation().navigate('SearchResult', params),
                goToAdvisorProfile: (params) => routeStore.getNavigation().replace('AdvisorProfile', params),
                ProfilePaymentsHistory: (params) =>
                  routeStore.getNavigation().navigate('ProfilePaymentsHistory', params),
                ProfilePaymentsHistoryDetail: (params) =>
                  routeStore
                    .getNavigation()
                    .navigate('ProfilePaymentsHistoryDetail', { transaction_id: _.get(params, 'id') }),
              };

              ctx.apply('REF.setRef', 'routes', routes);
              return routes;
            }),
          },
        },
      ],
    ],
  },
});
export default bindData;

import React from 'react';

import { bindings, hook } from '@vl/redata';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import fbFnsClient from '@vl/mod-clients/fibGatsbyFns';
import useObservableSource from '@vl/hooks/useObservableSource';
import useSubjectSource from '@vl/hooks/useSubjectSource';
import VoucherModelForrmater from '@uz/unitz-models/VoucherModel/formatter';
import UserWalletModel from '@uz/unitz-models/UserWalletModel';
import UserWalletModelFormater from '@uz/unitz-models/UserWalletModel/formatter';
import _ from 'lodash';
import { notification } from 'antd';

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
const bindData = bindings({
  redeemModal: {
    rules: [
      [
        'data',
        {
          data: {
            listWallets: hook((ctx) => {
              const userId = ctx.apply('authModel.getUserId');
              const listWallets = useObservableSource(() =>
                UserWalletModel.find(
                  `where: {user_id: {_eq: "${userId}"}}`,
                  `wallets {
                  balance {
                    amount_available
                  }
                }
                id`
                )
              );
              return listWallets;
            }),
            wallet: hook((ctx) => {
              const listWallets = useSubjectSource(ctx.get('listWallets'));
              const wallets = _.get(listWallets, '0.wallets');
              return {
                amountBalance: UserWalletModelFormater.amountBalance(ctx)(wallets),
              };
            }),
            form: hook((ctx) => {
              const userId = ctx.apply('authModel.getUserId');
              ctx.apply('REF.setRef', 'amountBalance', ctx.get('wallet.amountBalance'));
              const [isModalVisible, setIsModalVisible] = React.useState(true);
              const form = useFormik({
                initialValues: {
                  code: '',
                },
                onSubmit: async (values, actions) => {
                  try {
                    const code = _.get(values, 'code');
                    const res = await fbFnsClient.getClient().post('promotion-redeemVoucher', {
                      user_id: userId,
                      code,
                    });
                    const voucher = _.get(res, 'data');
                    const amount = VoucherModelForrmater.priceTxt(ctx)(voucher);
                    await sleep(2000);
                    const handler = {
                      success: () => {
                        ctx.apply('redeemModel.hide');
                        notification.open({
                          message: ctx.apply('i18n.t', 'Voucher.RedeemStatus.titleSuccess'),
                          description: ctx.apply('i18n.t', 'Voucher.RedeemStatus.success', { money: amount }),
                          // btn,
                          // key,
                          // onClose: close,
                        });
                      },
                      fail: () => {
                        console.log('hello world');
                        notification.open({
                          message: ctx.apply('i18n.t', 'Voucher.RedeemStatus.titleFail'),
                          description: ctx.apply('i18n.t', 'Voucher.RedeemStatus.fail'),
                          // btn,
                          // key,
                          // onClose: close,
                        });
                      },
                    };
                    const status = _.get(res, 'status', 'fail');
                    if (typeof _.get(handler, status) === 'function') handler[status]();
                  } catch (err) {}
                },
                validationSchema: Yup.object().shape({
                  code: Yup.string().required(ctx.apply('i18n.t', 'Form.required')),
                }),
              });
              _.assign(form, {
                isModalVisible,
                canSubmit: !_.get(form, 'isSubmitting') && _.get(form, 'isValid'),
                showModal: () => {
                  setIsModalVisible(true);
                },
                handleCancel: () => {
                  setIsModalVisible(false);
                },
              });
              return form;
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
